import { EventEmitter } from 'events';

import Chat from 'dt-common/constants/Chat';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import {
  ChatDispatcher,
  PlayerDispatcher,
  SettingsDispatcher,
} from '~/flux/dispatchers';
import text, { getChatCommandEmote } from '~/text';
import Colors from '~/constants/Colors';
import Config from '~/constants/Config';

const { GENERAL_COMMANDS, FREE_EMOTES } = Chat;
const { NOTIFICATION_COLOR } = Colors;
const DEFAULT_GENDER = { subject: 'they', object: 'them', possessive: 'their' };

let _socket;
let playerId;
let _muted_list = [];

// the stuff we serve
const userObject = {
  displayName: null,
  gender: DEFAULT_GENDER,
  chatTextColor: '#ffffff',
};
let current_room_name;
let message_cache = {};

const ChatStore = Object.assign({}, EventEmitter.prototype, {
  CHAT_ROOM_JOINED: 'CHAT_ROOM_JOINED',
  CHAT_ROOM_LEFT: 'CHAT_ROOM_LEFT',
  GOT_MESSAGE_EVENT: 'GOT_MESSAGE_EVENT',
  MESSAGE_CACHE_INITIALIZED: 'MESSAGE_CACHE_INITIALIZED',
  SHOW_CHAT_TEXT_COLOR_PICKER: 'SHOW_CHAT_TEXT_COLOR_PICKER',

  getAll() {
    return {
      userObject,
      message_cache,
      current_room_name,
    };
  },
});
export default ChatStore;

PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
ChatDispatcher.register(
  registerDispatchHandlers({
    [ChatDispatcher.ENTER_MESSAGE]: onMessageEntered,
    [ChatDispatcher.JOIN_NEW_CHAT_ROOM]: joinNewChatRoom,
    [ChatDispatcher.LEAVE_CHAT_ROOM]: leaveChatRoom,
    [ChatDispatcher.SELECT_CHAT_ROOM]: onChatRoomSelected,
    [ChatDispatcher.SET_CHAT_TEXT_COLOR]: requestChatTextColorChange,
  })
);

SettingsDispatcher.register(
  registerDispatchHandlers({
    [SettingsDispatcher.CHANGE_BROWSER_LANGUAGE]: onBrowserLanguageChanged,
  })
);

awaitSocket(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket;

  if (!_socket.has_ChatStore_listeners) {
    _socket.on('chat_room_joined', onChatRoomJoined);
    _socket.on('chat_room_left', onChatRoomLeft);
    _socket.on('chat_text', onChatText);
    _socket.on('chat_emote', onChatEmote);
    _socket.on('invalid_emote', onInvalidEmote);
    _socket.on('newPlayerName', onNewPlayerName);
    _socket.on('invalid_gender_pronoun', onInvalidGenderPronoun);
    _socket.on('updated_gender', onGenderUpdated);
    _socket.on('chatTextColorChanged', onChatTextColorChanged);
    _socket.on('online_player_names', onOnlinePlayerNames);
    _socket.on(
      'player_entered_standard_arena_matchmaking_queue',
      onMatchmakingNotif
    );
    _socket.on('player_silenced', onPlayerSilenced);
    _socket.on('player_unsilenced', onPlayerUnsilenced);
    _socket.on('player_muted_list_change', onPlayerMutedListChange);
    _socket.on('got_muted_player_list', onGotMutedPlayerList);

    _socket.has_ChatStore_listeners = true;
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action;
    const {
      scriptData: { displayName, chat },
      privateData,
    } = player;

    playerId = player._id;
    userObject.displayName = displayName;
    userObject.gender = chat ? chat.gender || DEFAULT_GENDER : DEFAULT_GENDER;
    userObject.chatTextColor = chat ? chat.textColor || '#ffffff' : '#ffffff';

    _muted_list = privateData?.chat?.muted_list || [];

    // join the global channel automatically
    _socket.emit('join_chat_channel', {
      playerId,
      channel: 'global',
    });
    // join locale-specific lobby of limited size
    // timeout because of redlock
    setTimeout(() => {
      _socket.emit('join_chat_channel', {
        playerId,
        channel: 'lobby',
        locale: Config.LOCALE,
      });
    }, 800);
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerLoggedIn',
      action: JSON.stringify(action),
    });
  }
}

function joinNewChatRoom(action) {
  const { channel, locale } = action;
  if (
    channel.includes('lobby') &&
    Object.keys(message_cache).find(
      (room_name) => room_name.split('-')[1] === locale
    )
  )
    return;

  _socket.emit('join_chat_channel', {
    playerId,
    channel,
    locale,
  });
}

function onChatRoomJoined({ room_name }) {
  if (room_name.includes('lobby')) current_room_name = room_name;

  if (room_name !== 'global') {
    !message_cache[room_name] && initMessageCache(room_name);
    ChatStore.emit(ChatStore.CHAT_ROOM_JOINED, { room_name });
  }
}

function initMessageCache(room_name) {
  if (room_name.includes('lobby')) {
    const room_locale = room_name.split('-')[1];

    message_cache[room_name] = [
      {
        actor: 'Wakefield Studios',
        text: text('ui.welcome_msg', room_locale),
        color: NOTIFICATION_COLOR,
      },
    ];

    Config.PLATFORM !== 'yandex' &&
      message_cache[room_name].push({
        text: text('ui.join_discord_msg', room_locale),
        color: NOTIFICATION_COLOR,
      });

    message_cache[room_name].push(
      ...[
        { text: text('chat.help_msg', room_locale), color: NOTIFICATION_COLOR },
        { line_break: true },
      ]
    );
  } else if (room_name !== 'global') {
    // TODO: add other room initialization messages (whispers, guild chat, etc.)
    message_cache[room_name] = [];
  }

  ChatStore.emit(ChatStore.GOT_MESSAGE_EVENT, { room_name });
}

function onBrowserLanguageChanged() {
  joinNewChatRoom({ channel: 'lobby', locale: Config.LOCALE });
}

function onChatRoomSelected(action) {
  current_room_name = action.room_name;
  ChatStore.emit(ChatStore.CURRENT_ROOM_UPDATED, { current_room_name });
}

function onChatRoomLeft({ room_name }) {
  delete message_cache[room_name];

  if (room_name === current_room_name) {
    // choose an arbitrary room to switch to
    current_room_name = Object.keys(message_cache)[0];
    ChatStore.emit(ChatStore.CURRENT_ROOM_UPDATED, { current_room_name });
  }

  ChatStore.emit(ChatStore.CHAT_ROOM_LEFT);
}

function leaveChatRoom(action) {
  _socket.emit('leave_chat_room', {
    playerId,
    room_name: action.room_name,
  });
}

function onMessageEntered(action) {
  try {
    if (action.text[0] === '/') {
      const command = action.text.split(' ')[0];
      const target = action.text.slice(command.length + 1);

      switch (command) {
        case '/challenge':
          // this is handled by the ArenaLobbyStore - return out here so we don't default to sending an emote
          return;

        case '/color':
          ChatStore.emit(ChatStore.SHOW_CHAT_TEXT_COLOR_PICKER);
          return;

        case '/emotes':
          addLineBreak();
          pushToLog(current_room_name, {
            text: text('chat.your_emotes'),
            notification: true,
            color: NOTIFICATION_COLOR,
          });
          for (const command of FREE_EMOTES) {
            pushToLog(current_room_name, {
              text: command,
              color: NOTIFICATION_COLOR,
            });
          }
          addLineBreak();
          return;

        case '/gender':
          let parsed_target =
            target === 'male'
              ? 'he/him/his'
              : target === 'female'
                ? 'she/her/her'
                : target;

          const [subject, object, possessive] = parsed_target.split('/');
          if (
            typeof subject !== 'string' ||
            subject.length > 7 ||
            typeof object !== 'string' ||
            object.length > 7 ||
            typeof possessive !== 'string' ||
            possessive.length > 7
          ) {
            for (const msg of text('ui.invalid_gender_input')) {
              $addMessageLogMessage(msg, Colors.RED);
            }
            return;
          }

          _socket.emit('set_gender_pronouns', {
            playerId,
            subject,
            object,
            possessive,
          });
          return;

        case '/help':
          addLineBreak();
          const info_text = text('chat.help_info');
          for (let i = 0; i < info_text.length; ++i) {
            pushToLog(current_room_name, {
              text: info_text[i],
              notification: i === 0,
              color: NOTIFICATION_COLOR,
            });
          }
          addLineBreak();
          return;

        case '/mute':
          _socket.emit('mute_player', { playerId, target });
          return;

        case '/unmute':
          _socket.emit('unmute_player', { playerId, target });
          return;

        case '/muted':
          _socket.emit('get_muted_player_list', { playerId, target });
          return;

        case '/online':
          _socket.emit('get_online_player_names', {
            playerId,
            current_room_name,
          });
          return;

        case '/silence':
          _socket.emit('silence_player', { playerId, target });
          return;

        case '/unsilence':
          _socket.emit('unsilence_player', { playerId, target });
          return;

        default:
          if (!userObject.displayName) {
            pushToLog(current_room_name, {
              text: text('chat.make_a_username'),
              notification: true,
              color: NOTIFICATION_COLOR,
            });
            return;
          }

          if (FREE_EMOTES.includes(command)) {
            if (target.length > 20) {
              onInvalidEmote({ message: 'Emote target too long.' });
              return;
            }

            _socket.emit('publish_chat_emote', {
              playerId,
              room_name: current_room_name,
              command,
              target_displayName: target,
            });
            return;
          }

          _socket.emit('chatCommand', {
            playerId,
            command: action.text,
          });
          return;
      }
    } else if (userObject.displayName) {
      _socket.emit('publish_chat_text', {
        playerId,
        room_name: current_room_name,
        text: action.text,
      });
    } else {
      pushToLog(current_room_name, {
        text: text('chat.make_a_username'),
        notification: true,
        color: NOTIFICATION_COLOR,
      });
    }
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onMessageEntered',
      action,
    });
  }
}

function onChatText(data) {
  try {
    const { room_name, actor, text, timestamp } = data;

    if (_muted_list.includes(actor.playerId)) return;

    const message_data = {
      actor: actor.displayName || text('ui.new_player'),
      color: actor.textColor,
      text,
      timestamp,
    };

    if (room_name === 'global')
      for (const prop in message_cache) pushToLog(prop, message_data);
    else pushToLog(room_name, message_data);
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onChatText',
      data: JSON.stringify(data),
    });
  }
}

function onChatEmote(data) {
  try {
    const { room_name, command, actor, target_displayName } = data;
    const emote_text = getChatCommandEmote(
      command,
      actor,
      target_displayName,
      room_name
    );
    pushToLog(room_name, {
      color: actor.textColor,
      emote: true,
      text: emote_text,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onChatEmote',
      data,
    });
  }
}

function onInvalidEmote(data) {
  $addMessageLogMessage(
    `${text('ui.invalid_emote_command')}: ${data.message}`,
    Colors.RED
  );
}

function onInvalidGenderPronoun(data) {
  $addMessageLogMessage(
    `${text('ui.invalid_gender_input')[0]}: ${data.message}`,
    Colors.RED
  );
}

function onGenderUpdated(data) {
  try {
    userObject.gender = data.gender;

    pushGlobalMessageToLogs({
      text: text('chat.gender_changed')(data.gender),
      color: NOTIFICATION_COLOR,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onGenderUpdated',
      data,
    });
  }
}

function pushToLog(room_name, message_data) {
  message_cache[room_name].push(message_data);
  ChatStore.emit(ChatStore.GOT_MESSAGE_EVENT, { room_name, message_data });
}

function pushGlobalMessageToLogs(message_data) {
  for (const room_name in message_cache) {
    message_cache[room_name].push(message_data);
    ChatStore.emit(ChatStore.GOT_MESSAGE_EVENT, { room_name, message_data });
  }
}

function onNewPlayerName({ newPlayerName }) {
  userObject.displayName = newPlayerName;
}

function requestChatTextColorChange(action) {
  _socket.emit('set_chat_text_color', {
    playerId,
    color: action.color,
  });
}

function onChatTextColorChanged(data) {
  userObject.chatTextColor = data.newColor;
  pushGlobalMessageToLogs({
    text: text('ui.chat_color_change_success'),
    notification: true,
    color: data.newColor,
  });
}

function onOnlinePlayerNames(data) {
  try {
    let log_text = '';
    for (const name of data.online_player_names) {
      log_text += `${name}, `;
    }
    log_text = log_text.slice(0, log_text.length - 2); // snip the trailing comma

    const anonymous_text = data.num_anonymous
      ? ` ... and ${data.num_anonymous} Anonymous ...`
      : '';

    pushToLog(current_room_name, {
      text: `${text('chat.players_online')}: ${log_text}${anonymous_text}`,
      color: NOTIFICATION_COLOR,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onOnlinePlayerNames',
      data,
    });
  }
}

function addLineBreak(num = 1) {
  for (let i = 0; i < num; ++i) {
    pushToLog(current_room_name, { line_break: true });
  }
}

function onMatchmakingNotif({ game_submode, player_display_name, timestamp }) {
  try {
    pushGlobalMessageToLogs({
      color: NOTIFICATION_COLOR,
      notification: true,
      text: text('chat.notifications.matchmaking_queue')({
        game_submode,
        player_display_name,
      }),
      timestamp,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onMatchmakingNotif',
      game_submode,
      player_display_name,
    });
  }
}

function onPlayerSilenced({ target }) {
  try {
    $addMessageLogMessage(`${target} has been silenced.`, Colors.GREEN);
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerSilenced',
      target,
    });
  }
}

function onPlayerUnsilenced({ target }) {
  try {
    $addMessageLogMessage(`${target} has been unsilenced.`, Colors.GREEN);
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerSilenced',
      target,
    });
  }
}

function onPlayerMutedListChange(data) {
  try {
    const { muted_list, muted_displayName, unmuted_displayName } = data;
    _muted_list = muted_list;

    if (muted_displayName)
      $addMessageLogMessage(
        text('chat.muted_player')(muted_displayName),
        Colors.GREEN
      );
    else if (unmuted_displayName)
      $addMessageLogMessage(
        text('chat.unmuted_player')(unmuted_displayName),
        Colors.GREEN
      );
    else throw new Error('No muted or unmuted player name provided.');
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerMutedListChange',
      data: JSON.stringify(data),
    });
  }
}

function onGotMutedPlayerList({ muted_player_displayNames }) {
  try {
    pushToLog(current_room_name, {
      text: `${text('chat.muted_players_heading')}: ${muted_player_displayNames.join(', ')}`,
      color: NOTIFICATION_COLOR,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onGotMutedPlayerList',
      muted_player_displayNames,
    });
  }
}
