import { Dispatcher } from 'flux';

const SettingsDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'SETTINGS_DISPATCHER',

  CHANGE_BROWSER_LANGUAGE: 'CHANGE_BROWSER_LANGUAGE',
  SAVE_SETTINGS: 'SAVE_SETTINGS',
  TOGGLE_PROFANITY_FILTER: 'TOGGLE_PROFANITY_FILTER',

  handleSettingsAction: function (action) {
    this.dispatch({
      source: SettingsDispatcher.NAME,
      action,
    });
  },
});
export default SettingsDispatcher;
