import { EventEmitter } from 'events';
import SettingsDispatcher from '~/flux/dispatchers/SettingsDispatcher';
import { registerDispatchHandlers } from '~/Tools';

// the stuff we serve:
let musicVolume = parseFloat(localStorage.getItem('dt_musicVolume'), 10);
if (isNaN(musicVolume)) {
  musicVolume = 0.5;
}
let sfxVolume = parseFloat(localStorage.getItem('dt_sfxVolume'), 10);
if (isNaN(sfxVolume)) {
  sfxVolume = 0.5;
}
let filter_profanity = localStorage.getItem('dt_filter_profanity') !== 'false';

const SettingsStore = Object.assign({}, EventEmitter.prototype, {
  BROWSER_LANGUAGE_CHANGED: 'BROWSER_LANGUAGE_CHANGED',
  PROFANITY_FILTER_TOGGLED: 'PROFANITY_FILTER_TOGGLED',

  getAll() {
    return {
      musicVolume,
      sfxVolume,
      filter_profanity,
    };
  },
});
export default SettingsStore;

SettingsDispatcher.register(
  registerDispatchHandlers({
    [SettingsDispatcher.CHANGE_BROWSER_LANGUAGE]: changeBrowserLanguage,
    [SettingsDispatcher.SAVE_SETTINGS]: saveSettings,
    [SettingsDispatcher.TOGGLE_PROFANITY_FILTER]: toggleProfanityFilter,
  })
);

function changeBrowserLanguage() {
  setTimeout(
    () => SettingsStore.emit(SettingsStore.BROWSER_LANGUAGE_CHANGED),
    100
  );
}

function saveSettings(action) {
  musicVolume = action.settings.musicVolume;
  localStorage.setItem('dt_musicVolume', musicVolume);

  sfxVolume = action.settings.sfxVolume;
  localStorage.setItem('dt_sfxVolume', sfxVolume);
}

function toggleProfanityFilter() {
  filter_profanity = !filter_profanity;
  localStorage.setItem('dt_filter_profanity', filter_profanity.toString());
  SettingsStore.emit(SettingsStore.PROFANITY_FILTER_TOGGLED, filter_profanity);
}
