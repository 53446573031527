<template>
  <Pressable
    :class="{
      'pointer-events-none': !!props.disabled,
      'pointer-events-auto': !props.disabled,
    }"
  >
    <button
      :class="{
        'border border-white rounded-sm font-bold text-xl': true,
        'bg-yellow-700 hover:bg-yellow-500': props.type === 'warning',
        'bg-orange-700 hover:bg-orange-500': props.type === 'danger',
        'bg-red-900 hover:bg-red-700': props.type === 'error',
        'bg-blue-900 hover:bg-blue-700': props.type === 'info',
        'bg-zinc-700 hover:bg-zinc-600': props.type === 'neutral',
        'bg-green-900 hover:bg-green-700': props.type === 'success',
        'bg-pink-400 hover:bg-pink-300': props.type === 'portal',
        'bg-zinc-900 hover:bg-zinc-900 pointer-events-none': !!props.disabled,
        'pointer-events-auto': !props.disabled,
      }"
      :disabled="props.disabled"
    >
      <slot></slot>
    </button>
  </Pressable>
</template>

<script setup>
import Pressable from './Pressable.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'neutral',
  },
});
</script>