import SettingsDispatcher from '~/flux/dispatchers/SettingsDispatcher';

const SettingsActions = {
  changeBrowserLanguage: function (new_lang) {
    SettingsDispatcher.handleSettingsAction({
      actionType: SettingsDispatcher.CHANGE_BROWSER_LANGUAGE,
      new_lang,
    });
  },

  saveSettings: function (settings) {
    SettingsDispatcher.handleSettingsAction({
      actionType: SettingsDispatcher.SAVE_SETTINGS,
      settings,
    });
  },

  toggleProfanityFilter: function () {
    SettingsDispatcher.handleSettingsAction({
      actionType: SettingsDispatcher.TOGGLE_PROFANITY_FILTER,
    });
  },
};
export default SettingsActions;
