<template>
  <div
    id="chat_body_scroll"
    ref="chat_body_scroll"
    class="grow text-grey-700 max-h-[100%] pt-2 border-r border-r-gray-600 bg-zinc-900/80 overflow-y-auto text-xs"
    @scroll="onScroll"
  >
    <div
      v-for="(message, i) of messages_to_display"
      :key="i"
    >
      <!-- case: line break -->
      <br v-if="message.line_break" />

      <!-- case: emote/command/notif -->
      <div
        v-else-if="message.emote || message.command || message.notification"
        :style="{
          color: message.color || '#fff',
          fontWeight: message.notification ? 'bold' : 'normal',
        }"
      >
        <div :class="{ 'italic ml-2': !!message.emote }">
          <span
            v-if="!!message.timestamp"
            class="mr-1"
            style="font-size: 10px"
            >{{ parseTimestamp(message.timestamp) }}</span
          >
          <span v-html="formatText(message.text)"></span>
        </div>
      </div>

      <!-- case: player chat message -->
      <div
        v-else
        class="flex"
        :style="{ color: message.color || '#fff' }"
      >
        <!-- timestamp | username | colon -->
        <span
          class="mr-1"
          style="font-size: 10px"
          >{{ parseTimestamp(message.timestamp) }}</span
        >
        <UserNameplate :username="message.actor" />
        <span
          v-if="!!message.actor"
          class="font-bold"
          >:</span
        >
        <!-- message body -->
        <div
          v-html="formatText(message.text)"
          :class="{ 'ml-2': !!message.actor }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Profanity, CensorType } from '@2toad/profanity';
import { ref, onMounted, onBeforeUnmount, onUpdated } from 'vue';
import Chat from 'dt-common/constants/Chat';
import { Colors, Config, Locales } from '~/constants';
import { ChatStore, SettingsStore } from '~/flux/stores';
import { UserNameplate } from '~/view/components/common/DOM';

const _profanity = new Profanity({
  languages: Chat.PROFANITY_FILTER_SUPPORTED_LANGUAGES,
  wholeWord: false,
});

const WHITELISTED_URLS = {
  'https://discord.gg/ysgHx3ErwJ': 1,
  'https://qa.dungeonteam.com': 1,
};

const chat_body_scroll = ref(null);
const messages_to_display = ref(null);

let _is_scrolling = false;

onMounted(() => {
  ChatStore.on(ChatStore.GOT_MESSAGE_EVENT, onGotMessage);
  ChatStore.on(ChatStore.CURRENT_ROOM_UPDATED, onCurrentRoomUpdated);
  SettingsStore.on(
    SettingsStore.PROFANITY_FILTER_TOGGLED,
    onProfanityFilterToggled
  );

  const { message_cache, current_room_name } = ChatStore.getAll();
  current_room_name && onGotMessage({ room_name: current_room_name });
});

onBeforeUnmount(() => {
  ChatStore.removeListener(ChatStore.GOT_MESSAGE_EVENT, onGotMessage);
  ChatStore.removeListener(
    ChatStore.CURRENT_ROOM_UPDATED,
    onCurrentRoomUpdated
  );
  SettingsStore.removeListener(
    SettingsStore.PROFANITY_FILTER_TOGGLED,
    onProfanityFilterToggled
  );
});

onUpdated(() => {
  // keep scroll cinched to bottom when new message lines are added
  if (
    !_is_scrolling &&
    chat_body_scroll.value.scrollHeight - chat_body_scroll.value.scrollTop <
      window.innerHeight * 0.82
  ) {
    chat_body_scroll.value.scrollTop = chat_body_scroll.value.scrollHeight;
  }
});

const onGotMessage = ({ room_name }) => {
  const { message_cache, current_room_name } = ChatStore.getAll();

  if (room_name === current_room_name) {
    let room_message_cache = message_cache[room_name];
    // CrazyGames players may have disabled chat in platform settings...\
    if (
      Config.PLATFORM === 'crazygames' &&
      window?.CrazyGames?.SDK?.game?.settings?.disableChat
    )
      room_message_cache = room_message_cache.filter((msg) => {
        return msg.notification || msg.color === Colors.NOTIFICATION_COLOR;
      });

    messages_to_display.value = normalizeMessageCache(room_message_cache);
  }
};

const normalizeMessageCache = (raw) => {
  const MAX_TO_RENDER = _is_scrolling ? 5000 : 2000;
  return raw.slice(0, MAX_TO_RENDER);
};

function onCurrentRoomUpdated({ current_room_name }) {
  onGotMessage({ room_name: current_room_name });
}

function formatText(text) {
  const result = text
    // don't allow HTML injection
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    // only allow whitelisted links
    .replace(LINK_REGEX, (url) =>
      WHITELISTED_URLS[url]
        ? `<a href=${url} target=_blank>${url}</a>`
        : '[URL]'
    );

  if (SettingsStore.getAll().filter_profanity) {
    const { current_room_name } = ChatStore.getAll();
    const room_locale = current_room_name.includes('lobby')
      ? current_room_name.split('-')[1]
      : Config.LOCALE;

    if (Chat.PROFANITY_FILTER_SUPPORTED_LANGUAGES.includes(room_locale))
      return _profanity.censor(result, CensorType.Word, [room_locale]);
  }

  return result;
}
const LINK_REGEX =
  /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/gi;

function parseTimestamp(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  return `[${date.toLocaleTimeString()}]`.replace(' AM', '').replace(' PM', '');
}

function onProfanityFilterToggled() {
  const { message_cache, current_room_name } = ChatStore.getAll();
  messages_to_display.value = normalizeMessageCache(
    message_cache[current_room_name]
  );
}

function onScroll(event) {
  _is_scrolling = true;
  if (!chat_body_scroll.value) {
    return;
  }
  const { clientHeight, scrollHeight, scrollTop } = chat_body_scroll.value;
  if (Math.abs(scrollTop - scrollHeight) < clientHeight + 25) {
    _is_scrolling = false;
  }
}
</script>
