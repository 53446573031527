import AccountDispatcher from '~/flux/dispatchers/AccountDispatcher';

var AccountActions = {
  authenticate: function () {
    AccountDispatcher.handleAccountAction({
      actionType: AccountDispatcher.AUTHENTICATE,
    });
  },

  doGuestLogin: function () {
    AccountDispatcher.handleAccountAction({
      actionType: AccountDispatcher.DO_GUEST_LOGIN,
    });
  },

  endSession: function () {
    AccountDispatcher.handleAccountAction({
      actionType: AccountDispatcher.END_SESSION,
    });
  },

  logOut: function () {
    AccountDispatcher.handleAccountAction({
      actionType: AccountDispatcher.LOG_OUT,
    });
  },
};
export default AccountActions;
