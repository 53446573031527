export default {
  error: {
    no_muting_self: 'Mute yourself? Really?',
    no_player_by_that_name: 'No player by that name was found.',
    player_name_rule_1: 'Name must not begin or end with a space.',
    player_name_rule_2: 'Name must be between 5~20 characters.',
    hero_build_name_rule_2: 'Name must be between 1~20 characters.',
    pronoun_rule_2: 'Pronoun must be between 1~10 characters.',
    player_name_rule_3:
      'Name can contain unicode characters, spaces, or any of the following: `~!@#$%^&*()-_+=[]{}|\\/;\':",.<>?',
    player_name_rule_4: 'Name cannot contain consecutive spaces.',
    player_name_rule_5: 'Name cannot contain consecutive underscores.',
    player_name_rule_6: 'Name cannot contain profanity.',
    username_taken: 'That username has been taken!',
  },
  illegal: {
    ability_at_max_level: 'Ability is already at max level!',
    affinity_at_max_level: 'Affinity is already at max level!',
    affinity_at_zero: 'Affinity is already at zero!',
    caverns_enemy_blocking: 'An enemy is blocking the exit.',
    no_inn_challenges_left: 'No challenges left!',
    no_opponent_found: 'No opponent found.',
    not_enough_ability_points: 'Not enough ability points!',
    not_enough_attribute_points: 'Not enough attribute points!',
    opponent_not_online: 'Opponent is not online at the moment.',
    prayed_for_self: 'Hmph. Selfish.',
  },
};
